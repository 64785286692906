import scrollIntoView from "scroll-into-view";

export default class ScrollHandler {
  public static ScrollIntoView(
    element: HTMLElement,
    dockTo: "CENTER" | "TOP" | "BOTTOM" = "CENTER",
    withOffset = false,
    time = 500,
    callback = undefined,
  ) {
    //Keep clearing timeout until it has stopped scrolling
    const navigationContainer = $(".navigation-container");
    const topOffset =
      navigationContainer.length > 0
        ? navigationContainer.find(".top-menu").first().height()
        : 0;
    let top: number;
    switch (dockTo) {
      case "TOP":
        top = 0;
        break;
      case "BOTTOM":
        top = 1;
        break;
      default:
        top = 0.5;
        break;
    }
    scrollIntoView(
      element,
      {
        time: time,
        align: {
          top: top,
          topOffset: withOffset ? topOffset : 0,
        },
        cancellable: false,
        maxSynchronousAlignments: 1,
      },
      callback,
    );
    if ($(element).hasClass("construction-window")) {
      const newEvent = new CustomEvent("update-world-position");
      element.dispatchEvent(newEvent);
    }
  }
}
