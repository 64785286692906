import "../../resources/less/Admin/Login/Login.less";
import ScrollHandler from "../components/ScrollHandler";

class AdminLogin {
  constructor() {
    // scroll to products link
    $("#landing-products-scrollto").on("click", () => {
      ScrollHandler.ScrollIntoView($("#landing-products")[0], "TOP");
    });
  }
}

$(window).on("load", () => {
  new AdminLogin();
});
